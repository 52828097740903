import React from 'react';
import { Link } from 'gatsby';

import Heading from '../components/heading';
import Layout from '../components/layout';
import SEO from '../components/seo';
import Nav from '../components/nav';
import theme from '../theme/theme';

const IndexPage = () => (
  <Layout>
    <SEO title="Designer & developer in Austin, TX" />
    <Nav />
    <main
      css={{
        display: 'flex',
        flexGrow: 1,
        flexDirection: 'column',
        justifyContent: 'center',
        padding: '16px 0',
      }}
    >
      <div css={{ maxWidth: '65ch' }}>
        <Heading level="1" size="900" css={{ marginBottom: 16 }}>
          I&rsquo;m Tyler Reinhart, a developer &amp; designer based in Austin, TX
        </Heading>
        <p css={{ ...theme.text['600'], marginBottom: 16 }}>
          I specialize in building beautiful &amp; performant apps for iOS &amp; web. Some of my
          favorite tools include Django, GraphQL, and React Native.{' '}
        </p>
        <Link
          to="/contact"
          css={{ textDecoration: 'none', color: theme.colors.bright, ...theme.text['600'] }}
        >
          Get in touch &raquo;
        </Link>
      </div>
    </main>
  </Layout>
);

export default IndexPage;
